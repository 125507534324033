/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import { createInput } from '@formkit/vue'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
  $attrs,
  createSection,
} from '@formkit/inputs'
import IMask from 'imask'
import countries from '@shared/constants/countries.json'
// import LMultiSelect from '@ui/components/LMultiSelect'
import LTelSelect from '@ui/components/LTelSelect'

/* TODO: Refactor this file to cleanup the mess */

const telSelect = createInput(LTelSelect, {
  props: [
    /* Multiselect Mixin */
    // 'id',
    'options',
    // 'value',
    'multiple',
    'trackBy',
    'labelBy',
    'searchable',
    'clearOnSelect',
    'hideSelected',
    'placeholder',
    'allowEmpty',
    'resetAfter',
    'closeOnSelect',
    'customLabel',
    'taggable',
    'tagPlaceholder',
    'tagPosition',
    'max',
    'optionsLimit',
    'group',
    'groupValues',
    'groupLabel',
    'groupSelect',
    'blockKeys',
    'internalSearch',
    'preserveSearch',
    'preselectFirst',

    /* Multiselect Component */
    // 'name',
    'selectLabel',
    'selectGroupLabel',
    'selectedLabel',
    'deselectLabel',
    'deselectGroupLabel',
    'showLabels',
    'limit',
    'limitText',
    'loading',
    // 'disabled',
    'maxHeight',
    'openDirection',
    'showNoResults',
    'tabIndex',

    /* Pointer Mixin */
    'showPointer',
    'optionsHeight',
  ],
})

const countrySelect = createSection('countrySelect', () => ({
  $cmp: 'FormKit',
  props: {
    type: telSelect,
    outerClass: 'l-multiselect absolute inset-y-0 left-0 flex items-center w-auto h-full !p-0 -m-[1px]',
    wrapperClass: 'h-full',
    labelClass: 'block mb-2 text-sm font-medium text-gray-900 formkit-invalid:text-red-700',
    innerClass: 'mb-0 bg-transparent border-transparent',
    inputClass: 'h-[40px] py-0 pl-3 pr-8',
    options: countries,
    trackBy: 'iso',
    labelBy: 'name',
    value: 'BR',
  },
  bind: '$attrs',
  attrs: {
    type: '$type',
    disabled: '$disabled',
    name: '$node.name',
    // onInput: '$handlers.DOMInput',
    onChangeCountry: '$handlers.changeCountry',
    onInput: '$handlers.changeCountry',
    onBlur: '$handlers.changeCountry',
    value: '$_value',
    id: '$id',
    'aria-describedby': '$describedBy',
  },
}))

/**
 * Input definition for a text.
 * @public
 */
export const tel: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    /* Outer */
    wrapper(
      /* Wrapper */
      label('$label'),
      $attrs(
        {
          class: '$: "tel-inner relative " + $classes.inner',
        },
        inner(
          /* Inner */
          icon('prefix', 'label'),
          prefix(
            // $attrs(
            //   {
            //     // select: '$handlers.changeCountry',
            //     onChangeCountry: '$handlers.changeCountry',
            //     onInput: '$handlers.changeCountry',
            //     // input: '$handlers.changeCountry',
            //     // onSelect: '$handlers.changeCountry',
            //   },
            //   countrySelect()
            // )
            countrySelect(),
          ),
          $attrs(
            {
              class: '$classes.input',
              style: 'padding-left: 60px',
              value: '$_mask.value',
            },
            textInput(),
          ),
          suffix(),
          icon('suffix'),
        ),
      ),
    ),
    help('$help'),
    messages(message('$message.value')),
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['country', 'unmask'],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'tel',
  /**
   * Additional features that should be added to your input
   */
  /**
   * Additional features that should be added to your input
   */
  features: [mask],
}

function normalizeMask(mask: string | string[]): IMask.AnyMaskedOptions {
  if (isString(mask)) {
    return { mask }
  }

  // Create IMask option
  return { mask: mask.map((m) => ({ mask: m })).reverse() }
}

function getMaskLength(mask: string) {
  return mask.replace(/\D/g, '').length
}

// function getMask(data, target) {
//   return data.reduce((acc, obj) => {
//     return Math.abs(target - getMaskLength(obj.mask)) < Math.abs(target - getMaskLength(acc.mask)) ? obj : acc
//   })
// }

function dynamicMask(appended: string, dynamicMasked: IMask.MaskedDynamic) {
  const number = (dynamicMasked.value + appended).replace(/\D/g, '')

  return dynamicMasked.compiledMasks.reduce((acc, obj) => {
    return Math.abs(number.length - getMaskLength(obj.mask as string)) <
      Math.abs(number.length - getMaskLength(acc.mask as string))
      ? obj
      : acc
  })
  // return getMask(dynamicMasked.compiledMasks, number.length)
}
// Prefix phone number with country code. If unsmaked value, remove space between code and phone
function addCountryPrefix(phone: string, code: string, unmask: boolean) {
  let result = code

  if (!unmask) {
    result = result + ' '
  }

  result = result + phone

  return result
}

function removeCountryPrefix(phone: string, code: string, unmask: boolean) {
  if (!unmask) {
    return phone.replace(code + ' ', '')
  }

  return phone.replace(code, '')
}

/**
 * Here we add our masks.
 */
function mask(node: FormKitNode) {
  const mask = ref<IMask.DeduceMasked<IMask.AnyMaskedOptions>>()

  const tempCountry = countries.find((country) => country.iso === 'BR')

  const normalizedMasks = normalizeMask(tempCountry!.mask)
  // We need to save the value so that we can restore it after the mask is updated.
  const tempValue = mask.value?.value

  // Create new mask each time the country changes.
  mask.value = IMask.createMask<IMask.AnyMaskedOptions>({
    ...normalizedMasks,
    dispatch: dynamicMask,
  })

  // Restore the value.
  if (tempValue) {
    mask.value.resolve(tempValue)
  }

  node.hook.input((value, next) => {
    const country = tempCountry!
    const unmask = fallback<boolean>(node.context?.unmask as boolean | undefined, true)

    value = value || ''

    const rawValue = removeCountryPrefix(value, country.code, unmask)
    const maskedValue = mask.value!.resolve(rawValue)
    console.log(value, rawValue, addCountryPrefix(unmask ? mask.value!.unmaskedValue : value, country.code, unmask))

    // Change the value to be emitted to masked or unmasked value, based on prop
    value = addCountryPrefix(unmask ? mask.value!.unmaskedValue : maskedValue, country.code, unmask)

    return next(value)
  })

  node.on('created', () => {
    if (node.context) {
      // Save the mask instance, so we can access it through the context
      node.context._mask = mask

      node.context.handlers.changeCountry = (event) => {
        // console.log(event)
      }
    }
  })

  node.on('dom-input-event', (e) => {
    const target = e.payload.target as HTMLInputElement

    // Update the input element to display the masked value
    target.value = mask.value!.value
  })

  node.on('prop:mask', () => {
    // If the mask has changed, update the value to reflect the new mask
    if (node.context) {
      node.input(node.context.value)
    }
  })
}
