import { IMutableContext, UnleashClient, plugin as unleashPlugin } from '@unleash/proxy-client-vue'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { user } = useAuth()
  const {
    public: { unleash },
  } = useRuntimeConfig()

  const unleashEnabled = unleash.enabled === 'true'
  const shouldInitUnleash = unleashEnabled && user

  // só inicia se a env UNLEASH_ENABLED ativa e se o usuário estiver logado
  if (shouldInitUnleash) {
    if (process.env.NODE_ENV === 'development') {
      console.log('[development] Unleash is enabled')
    }

    const client = new UnleashClient({
      ...unleash.config,
      refreshInterval: Number(unleash.config.refreshInterval),
    })

    if (user.value?.email) {
      // Atualiza o contexto do usuário
      // clientEmail e userId é o nome do campo que o Unleash usa para identificar o usuário
      // userId utilizdo sera o externalId do usuário
      await client.updateContext({ clientEmail: user.value.email, userId: user.value.externalId } as IMutableContext)
    }

    nuxtApp.vueApp.use(unleashPlugin, { unleashClient: client })
  }
})
