import type { FormKitNode } from '@formkit/core'
import type { LDropzoneFile } from '@ui/components/LDropzone'
import { LDropzoneStatus } from '@ui/components/LDropzone'

function validateFile(file: LDropzoneFile) {
  return file.status !== LDropzoneStatus.ERROR
}

export function fileRule(node: FormKitNode) {
  const value = node.value as (LDropzoneFile | null) | LDropzoneFile[]
  const multiple = node.context?.multiple as boolean

  if (!multiple && isNull(value)) {
    return true
  }

  if (!multiple && isObject(value)) {
    return validateFile(value as LDropzoneFile)
  }

  if (isArray(value)) {
    return value.every(validateFile)
  }

  return false
}
