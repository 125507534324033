
// @ts-nocheck


export const localeCodes =  [
  "pt-BR"
]

export const localeLoaders = {
  "pt-BR": [{ key: "../src/locales/pt-BR.ts", load: () => import("../src/locales/pt-BR.ts" /* webpackChunkName: "locale__root_project_src_locales_pt_BR_ts" */), cache: true },
{ key: "../src/domains/continuous-care/locales/pt-BR.ts", load: () => import("../src/domains/continuous-care/locales/pt-BR.ts" /* webpackChunkName: "locale__root_project_src_domains_continuous_care_locales_pt_BR_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/i18n.config.ts?hash=213b9648&config=1" /* webpackChunkName: "_root_project_src_i18n_config_ts_213b9648" */),
  () => import("../src/i18n.config.ts?hash=213b9648&config=1" /* webpackChunkName: "_root_project_src_i18n_config_ts_213b9648" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "/root/project/src/i18n.config.ts",
  "locales": [
    {
      "name": "Português",
      "code": "pt-BR",
      "iso": "pt-BR",
      "flag": "br",
      "files": [
        "locales/pt-BR.ts",
        "domains/continuous-care/locales/pt-BR.ts"
      ]
    }
  ],
  "defaultLocale": "pt-BR",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "Português",
    "code": "pt-BR",
    "iso": "pt-BR",
    "flag": "br",
    "files": [
      {
        "path": "locales/pt-BR.ts"
      },
      {
        "path": "domains/continuous-care/locales/pt-BR.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
