import type { FormKitNode } from '@formkit/core'
import { optionsLoader } from '@ui/formkit/inputs/selectbox/features/options'

export default function search(node: FormKitNode): void {
  const debounceOptionsLoader = useDebounceFn(async (node: FormKitNode) => await optionsLoader(node), node.props.debounce)

  node.on('created', async () => {
    if (node.context) {
      node.context.handlers.search = async (search: string) => {
        if (node.context) {
          node.context.search = search
          node.props.options = []
          await debounceOptionsLoader(node)
        }
      }
    }
  })
}
