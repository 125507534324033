import { createKeycloak, initKeycloak } from './keycloak'

export default defineNuxtPlugin(async (nuxtApp) => {
  const { config, initOptions } = useRuntimeConfig().public.keycloak
  const keycloak = createKeycloak(config)
  await initKeycloak(initOptions)

  return {
    provide: {
      keycloak,
    },
  }
})
