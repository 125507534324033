export function getFullName({
  firstName,
  lastName,
}: {
  firstName: string | null | undefined
  lastName: string | null | undefined
}) {
  const { t } = useNuxtApp().$i18n

  let fullName = ''

  if (firstName) {
    fullName = firstName
  }

  if (lastName) {
    fullName = `${fullName} ${lastName}`
  }

  return fullName || t('general.noName')
}
