import { reset, type FormKitNode } from '@formkit/core'
import { has, init } from '@formkit/utils'

export function lazyPlugin(node: FormKitNode) {
  node.on('created', async () => {
    // Add `lazy` prop
    node.addProps(['lazy'])

    // Disable lazy
    if (!has(node.props, 'lazy')) {
      return
    }

    // Default error handler
    node.on('lazy:error', ({ payload }) => {
      console.error(payload)
    })

    // Set `disabled` and `loading` states to `true`
    node.disturbLoading()

    try {
      const state = await node.props.lazy

      node.props.initial = init(state)

      // Don't populate if the hook return false.
      if (node.hook.beforeLazy.dispatch(state) !== false) {
        reset(node, state)
      }

      node.emit('lazy', state)
    } catch (e) {
      node.emit('lazy:error', e)
    } finally {
      // Set `disabled` and `loading` states to `false`
      node.calmLoading()
    }
  })
}
