import type { Ref } from 'vue'

export async function promisePending(promise: unknown, pending: Ref<boolean>) {
  const _promise = Promise.resolve(promise)
  let _resolved = false

  // Use Promise.race to wait for either `promise` or the `nextTick()` to finish
  await Promise.race([_promise.finally(() => (_resolved = true)), nextTick()])

  // If `promise` hasn't resolved yet (next tick), set pending to true
  if (!_resolved) {
    pending.value = true
  }

  // Wait for `promise` to complete
  await _promise

  // Finally, set pending to false
  pending.value = false
}
