import { PositionEnum } from '@staffs'
import { isGeneralTeam, isHealthTeam, isOpsTeam } from '../utils'

import type { KeycloakProfile } from 'keycloak-js'
import type { User } from '@auth'

export function useAuth() {
  const { keycloak, token, profile, roles, isAuthenticated, isPending } = useKeycloak()
  const user = ref<User | null>(null)
  const status = ref<'authenticated' | 'unauthenticated' | 'loading'>('unauthenticated')

  // User
  watch(profile, () => (user.value = profile.value ? resolveUser(profile.value) : null), { immediate: true })
  // Status
  watch(
    [isPending, isAuthenticated],
    () => (status.value = isPending.value ? 'loading' : isAuthenticated.value ? 'authenticated' : 'unauthenticated'),
    {
      immediate: true,
    },
  )

  return {
    // Token
    token,

    // Sign in/out
    logout: (): Promise<void> => keycloak.logout(),

    // Session
    user,

    // Status
    status,

    // Roles
    hasRole,
    unlessRole,
    hasAnyRole,

    isHealthTeam: () => (status.value === 'authenticated' ? isHealthTeam(user.value!) : false),
    isOpsTeam: () => (status.value === 'authenticated' ? isOpsTeam(user.value!) : false),
    isGeneralTeam: () => (status.value === 'authenticated' ? isGeneralTeam(user.value!) : false),
  }

  function resolveUser(profile: KeycloakProfile): User {
    return {
      id: profile.id!,
      externalId: profile.attributes!.externalId[0],
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
      fullName: getFullName(profile),
      email: profile.email!,
      picture: profile.attributes?.picture?.[0],
      position:
        profile.attributes?.position?.[0] ??
        (roles.value.filter((role) => Object.values(PositionEnum).includes(role as never))[0] as PositionEnum),
    }
  }

  function hasRole(role: PositionEnum) {
    return status.value === 'authenticated' ? user.value!.position === role : false
  }

  function unlessRole(role: PositionEnum) {
    return status.value === 'authenticated' ? user.value!.position !== role : false
  }

  function hasAnyRole(roles: PositionEnum[]) {
    return status.value === 'authenticated' ? roles.includes(user.value!.position) : false
  }
}
