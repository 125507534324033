import PQueue from 'p-queue'
import { type LDropzoneFile, LDropzoneStatus } from '..'

export interface UseQueueOptions {
  parallelUploads: number
  uploadMultiple: boolean
  upload: (files: LDropzoneFile[]) => void
}

export const useQueue = (innerValue: Ref<LDropzoneFile[]>, options: UseQueueOptions) => {
  const { parallelUploads, uploadMultiple, upload } = options

  const queue = new PQueue({
    concurrency: parallelUploads,
  })

  const queuedFiles = computed(() => innerValue.value.filter((file) => file.status === LDropzoneStatus.QUEUED))
  const uploadingFiles = computed(() => innerValue.value.filter((file) => file.status === LDropzoneStatus.UPLOADING))

  // Goes through the queue and processes images if there aren't too many already.
  const processQueue = () => {
    const processingLength = uploadingFiles.value.length
    let i = processingLength

    // There are already at least as many images uploading than should be
    if (processingLength >= parallelUploads) {
      return
    }

    const _queuedFiles = queuedFiles.value

    if (!(_queuedFiles.length > 0)) {
      return
    }

    if (uploadMultiple) {
      return upload(_queuedFiles.slice(0, parallelUploads - processingLength))
    } else {
      while (i < parallelUploads) {
        if (!_queuedFiles.length) {
          return
        } // Nothing left to process
        upload([_queuedFiles.shift()!])
        i++
      }
    }
  }

  const enqueueFile = (file: LDropzoneFile) => {
    if (file.status === LDropzoneStatus.ADDED) {
      file.status = LDropzoneStatus.QUEUED
      return setTimeout(() => processQueue(), 0) // Deferring the call
    } else {
      throw new Error("This file can't be queued because it has already been processed or was rejected.")
    }
  }

  return {
    queue,
    processQueue,
    enqueueFile,
  }
}
