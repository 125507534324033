import type { User } from '@auth'

export type EventAnalysticTrack = string | { name: string; properties?: Record<string, unknown> }

export const parseEventTrack = (event: EventAnalysticTrack) => {
  if (typeof event === 'string') {
    return { name: event, properties: undefined }
  }

  return event
}

export const useEventsAnalytics = () => {
  const mixpanel = useMixpanel()

  return {
    track: (name: string, properties?: Record<string, unknown>) => {
      return mixpanel.track(name, properties)
    },
    identify: (user: User | null) => {
      if (!user) return

      mixpanel.identify(user.externalId)
      if (!mixpanel.people) return // people only exists when mixpanel is enabled

      return mixpanel.people.set({ name: user.fullName, email: user.email, position: user.position })
    },
  }
}
