import type { User } from '@auth'

export function useErrorTracking() {
  const sentry = useSentry()
  return {
    captureException: (error: unknown) => {
      sentry.captureException(error)

      if (!window.newrelic) return
      if (!window.NREUM) return

      window.NREUM.noticeError(error)
    },
    identify: (user: User | null) => {
      sentry.setUser(
        user && {
          // Your internal identifier for the user.
          id: user.externalId,
          //The username. Typically used as a better label than the internal id.
          username: user.fullName,
          // An alternative, or addition, to the username. Sentry is aware of email addresses and can display things such as Gravatars and unlock messaging capabilities.
          email: user.email,
        },
      )

      if (!window.newrelic) return
      if (!window.NREUM) return

      window.NREUM.setUserId(user?.externalId)
      window.NREUM.setCustomAttribute('user.id', user?.externalId)
      window.NREUM.setCustomAttribute('user.email', user?.email)
      window.NREUM.setCustomAttribute('user.name', user?.fullName)
    },
  }
}
