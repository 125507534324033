import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ConfirmPopup from 'primevue/confirmpopup'
import Tooltip from 'primevue/tooltip'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { inputStyle: 'filled' })
  nuxtApp.vueApp.use(ConfirmationService)
  // TODO: Wrap PrimeVue components
  nuxtApp.vueApp.component('DataTable', DataTable)
  nuxtApp.vueApp.component('Column', Column)
  nuxtApp.vueApp.component('ConfirmPopup', ConfirmPopup)

  nuxtApp.vueApp.directive('tooltip', Tooltip)
})
