import type { LDropzoneContextProp, LDropzoneFile } from '@ui/components/LDropzone'
import type { Ref } from 'vue'
import { nanoid } from 'nanoid'
import { LDropzoneStatus } from '@ui/components/LDropzone'

export const useInnerValue = (context: Ref<LDropzoneContextProp>, multiple: Ref<boolean>) => {
  const innerValue = ref<LDropzoneFile[]>(initialValue(context.value._value))

  watch(innerValue, (newValue) => context.value.node.input(multiple.value ? [...newValue] : newValue[0] ?? null), {
    deep: true,
    immediate: true,
  })

  return innerValue

  function initialValue(value: string | LDropzoneFile | (string | LDropzoneFile)[]) {
    let initialValue: (string | LDropzoneFile)[] = []

    if (Array.isArray(value)) {
      initialValue = value
    } else if (value) {
      initialValue = [value]
    }

    return initialValue.map<LDropzoneFile>((value) => {
      if (typeof value === 'object') {
        return value
      }

      return { _id: nanoid(4), id: value, name: value, file: null, status: LDropzoneStatus.UPLOADED, progress: 100, url: null }
    })
  }
}
