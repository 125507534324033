<script setup lang="ts">
import LMultiSelect from '../LMultiSelect'
import type { PropType } from 'vue'
import type { LMultiSelectContextProp } from '../LMultiSelect/types'

const props = defineProps({
  context: {
    type: Object as PropType<LMultiSelectContextProp>,
    required: true,
  },
})

const multiselectRef = ref<InstanceType<typeof LMultiSelect>>(null)
const telInnerEl = ref<HTMLElement | null>(null)
const { width: telInnerWidth } = useElementSize(telInnerEl)

const multiselectContentWrapperEl = ref<HTMLElement | null>(null)

watch(
  telInnerWidth,
  (value) => {
    if (multiselectContentWrapperEl.value) {
      multiselectContentWrapperEl.value.style.width = `${value}px`
    }
  },
  { immediate: true },
)

onMounted(() => {
  telInnerEl.value = multiselectRef.value.$el.closest('.tel-inner')
  multiselectContentWrapperEl.value = multiselectRef.value.$el.querySelector('.multiselect__content-wrapper')
})

const defaults = {
  allowEmpty: false,
}
</script>

<template>
  <LMultiSelect ref="multiselectRef" class="l-telselect" v-bind="{ ...$props, ...defaults, ...$attrs }">
    <template #singleLabel="singleLabelProps">
      <img class="h-5 w-5" :src="singleLabelProps.option.flag" :alt="singleLabelProps.option.name + ' flag'" />
    </template>
    <template #option="optionProps">
      <div class="inline-flex items-center">
        <img class="mr-2 h-5 w-5" :src="optionProps.option.flag" :alt="optionProps.option.name + ' flag'" />
        <span class="mr-2">{{ optionProps.option.name }}</span>
        <span class="country-code text-gray-500">{{ optionProps.option.code }}</span>
      </div>
    </template>
  </LMultiSelect>
</template>

<style lang="postcss">
.l-multiselect .l-telselect .multiselect {
  @apply w-14;

  /* stylelint-disable */
  :not(.multiselect-group) .multiselect__option--highlight {
    .country-code {
      @apply text-gray-300;
    }
  }

  .multiselect__select {
    @apply w-6 px-0;
  }

  .multiselect__single {
    @apply w-5;
  }

  .multiselect__tags {
    @apply rounded-r-none border-r-0 border-none bg-transparent;
  }
  /* stylelint-enable */
}
</style>
