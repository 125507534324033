export enum DocumentTypeEnum {
  CPF = 'CPF',
}

export enum ProfessionalDocumentTypeEnum {
  CRM = 'CRM',
  CRN = 'CRN',
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum PositionEnum {
  DOCTOR = 'DOCTOR',
  BE_SCI = 'BE_SCI',
  NUTRITIONIST = 'NUTRITIONIST',
  PHYSICAL_EDUCATOR = 'PHYSICAL_EDUCATOR',
  OPERATIONS = 'OPERATIONS',
  GENERAL = 'GENERAL',
}
