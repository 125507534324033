export default defineNuxtRouteMiddleware(async (to) => {
  const { keycloak, isAuthenticated } = useKeycloak()

  if (!isAuthenticated.value) {
    return await keycloak.login()
  }

  // const { t } = useNuxtApp().$i18n
  // const { cannot } = useAbility()
  // if (to.path.startsWith('/health') && cannot('read', 'Health')) {
  //   return createError({
  //     message: t('error.forbidden'),
  //     statusCode: 403,
  //   })
  // }
  //
  // if (to.path.startsWith('/ops') && cannot('read', 'OPS')) {
  //   return createError({
  //     message: t('error.forbidden'),
  //     statusCode: 403,
  //   })
  // }
})
