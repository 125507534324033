import { defineAbilityFor } from '../config/ability'

export interface AbilityPluginOptions {
  useGlobalProperties?: boolean
}

export default defineNuxtPlugin(async () => {
  // Init acl
  const { user } = useAuth()
  const ability = defineAbilityFor(user.value)

  // Identify user
  const errorTracking = useErrorTracking()
  errorTracking.identify(user.value)
  const events = useEventsAnalytics()
  events.identify(user.value)

  return {
    provide: {
      ability,
      can: ability.can.bind(ability),
    },
  }
})
