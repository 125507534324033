export function useActiveList<T = any>(callback: (item: T) => string = (id) => id as string) {
  const activeList = ref<string[]>([])
  const isActive = (item: T) => activeList.value.includes(callback(item))
  const addToList = (item: T) => {
    activeList.value.push(callback(item))
  }
  const removeFromList = (item: T) => {
    const index = activeList.value.indexOf(callback(item))
    activeList.value.splice(index, 1)
  }

  return {
    activeList,
    isActive,
    addToList,
    removeFromList,
  }
}
