import type { FormKitNode } from '@formkit/core'
import { optionsLoader } from '@ui/formkit/inputs/selectbox/features/options'

export default function pagination(node: FormKitNode): void {
  node.on('created', async () => {
    if (node.context) {
      node.context.page = 1
      node.context._hasNextPage = false
      node.context.hasNextPage = () => {
        if (node.context) {
          node.context._hasNextPage = true
        }
      }
      node.context.handlers.loadMore = async () => {
        if (node.context?._hasNextPage) {
          node.context._hasNextPage = false
          node.context.page += 1
          await optionsLoader(node)
        }
      }
    }
  })
}
