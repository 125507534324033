<script lang="ts" setup>
import Rating from 'primevue/rating'
import type { PropType } from 'vue'
import type { LRatingContextProp } from './types'

const props = defineProps({
  context: {
    type: Object as PropType<LRatingContextProp>,
    required: true,
  },
})

const innerValue = computed({
  // getter
  get() {
    return props.context._value
  },
  // setter
  set(newValue) {
    props.context.node.input(newValue)
  },
})

const test = () => {
  console.log('blur')
}
const test2 = () => {
  console.log('focus')
}
</script>

<template>
  <Rating
    v-model="innerValue"
    :stars="context.stars"
    :cancel="context.cancel || false"
    :on-icon="context.onIcon"
    :off-icon="context.offIcon"
    :cancel-icon="context.cancelIcon"
    :disabled="context.disabled"
    @focus="test2"
    @blur="test"
  >
    <!-- Slots -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </Rating>
</template>
