// Config & Theme & Lang
import { defineFormKitConfig } from '@formkit/vue'
import { en, pt } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import theme from './formkit.theme'
// Inputs
import { createProPlugin, rating, toggle, mask, dropdown, autocomplete, repeater, taglist, slider } from '@formkit/pro'
import { text, tel, form, radio, datepicker, multiselect, dropzone, selectbox } from './inputs'
import { fileRule } from './rules'
// Plugins
import { loadingPlugin, lazyPlugin, createHistoryPlugin } from './plugins'

export default defineFormKitConfig(() => {
  const { formkitKey } = useRuntimeConfig().public
  console.log('Formkit key', formkitKey)

  // Pro plugin
  const proPlugin = createProPlugin(formkitKey, {
    rating,
    toggle,
    mask,
    datepicker,
    dropdown,
    autocomplete,
    repeater,
    taglist,
    slider,
  })

  return {
    config: {
      classes: generateClasses(theme),
    },
    locales: { en, pt },
    locale: 'pt',
    inputs: {
      form,
      radio,
      text,
      tel,
      dropzone,
      multiselect,
      selectbox,
    },
    rules: {
      file: fileRule,
    },
    plugins: [proPlugin, loadingPlugin, createHistoryPlugin(), lazyPlugin],
    icons: {
      ...genesisIcons,
      invalid: `
      <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
      </svg>`,
    },
  }
})
