import type { LDropzoneContextProp } from '..'
import { AttachmentTypeEnum } from '@attachments'

export const useProps = (props: { context: LDropzoneContextProp }) => {
  const maxFiles = toRef(props.context, 'maxFiles', 0)
  const maxSize = toRef(props.context, 'maxSize', Infinity)
  const multiple = toRef(props.context, 'multiple', true)
  const parallelUploads = toRef(props.context, 'parallelUploads', 2)
  const uploadMultiple = toRef(props.context, 'uploadMultiple', false)
  const acceptedFiles = toRef(props.context, 'acceptedFiles', undefined)
  const disabled = toRef(props.context, 'disabled', false)
  const attachmentType = toRef(props.context, 'attachmentType', AttachmentTypeEnum.UNKNOWN)

  return {
    maxFiles,
    maxSize,
    multiple,
    parallelUploads,
    uploadMultiple,
    acceptedFiles,
    disabled,
    attachmentType,
  }
}
