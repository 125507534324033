import { type LDropzoneFile, LDropzoneStatus } from '..'
import PQueue from 'p-queue'
import { AttachmentTypeEnum } from '@attachments'

export interface UseUploadOptions {
  queue: PQueue
  processQueue: () => void
  attachmentType: AttachmentTypeEnum
  onError?: () => void
}

export const useUpload = (options: UseUploadOptions) => {
  const { queue, processQueue, attachmentType, onError = () => {} } = options

  const upload = (files: LDropzoneFile[]) => {
    for (const file of files) {
      // If already uploaded, jump to next file
      if (!file.file || file.status === LDropzoneStatus.UPLOADED) continue

      // Update status
      file.status = LDropzoneStatus.UPLOADING

      // Add promise to `uploading`
      const upload = createAttachment(
        { file: { file: file.file, name: file.name ?? file.file.name }, type: attachmentType },
        {
          onProgress: (progress) => {
            file.progress = Math.floor((progress.loaded / progress.total) * 100)
          },
        },
      )
        .then((response) => {
          // Set ID
          file.id = response.data.id
          file.url = response.data.isPublic ? response.data.url : response.data.presignedUrl
          file.response = response.data

          if (!file.id) {
            throw new Error("File doesn't have ID")
          }

          // Update status
          file.status = LDropzoneStatus.UPLOADED
        })
        .catch((e) => {
          console.error(e)
          // Update status
          file.status = LDropzoneStatus.ERROR
          onError()
        })
        .finally(() => {
          processQueue()
        })

      queue.add(() => upload)
    }
  }

  return {
    upload,
  }
}
