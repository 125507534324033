import { createInput } from '@formkit/vue'
import LMultiSelect from '@ui/components/LMultiSelect'

export const multiselect = createInput(LMultiSelect, {
  props: [
    /* Multiselect Mixin */
    // 'id',
    'options',
    // 'value',
    'multiple',
    'trackBy',
    'labelBy',
    'searchable',
    'clearOnSelect',
    'hideSelected',
    'placeholder',
    'allowEmpty',
    'resetAfter',
    'closeOnSelect',
    'customLabel',
    'taggable',
    'tagPlaceholder',
    'tagPosition',
    'max',
    'optionsLimit',
    'group',
    'groupValues',
    'groupLabel',
    'groupSelect',
    'blockKeys',
    'internalSearch',
    'preserveSearch',
    'preselectFirst',

    /* Multiselect Component */
    // 'name',
    'selectLabel',
    'selectGroupLabel',
    'selectedLabel',
    'deselectLabel',
    'deselectGroupLabel',
    'showLabels',
    'limit',
    'limitText',
    'loading',
    // 'disabled',
    'maxHeight',
    'openDirection',
    'showNoResults',
    'tabIndex',

    /* Pointer Mixin */
    'showPointer',
    'optionsHeight',
  ],
})
