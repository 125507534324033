<script setup lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import type { PropType } from 'vue'
import type { SelectboxInputContextProp } from './types'
import type { FormKitOptionsItem } from '@formkit/inputs'

const props = defineProps({
  context: {
    type: Object as PropType<SelectboxInputContextProp>,
    required: true,
  },
})

/* === PROPS === */
const label = toRef(props.context, 'label')
const placeholder = toRef(props.context, 'placeholder')
const emptyMessage = toRef(props.context, 'emptyMessage')
const searchable = toRef(props.context, 'searchable', false)
const debounce = toRef(props.context, 'debounce', 200)
const options = toRef(props.context, 'options', [])
const multiple = toRef(props.context, 'multiple', false)
const disabled = toRef(props.context, 'disabled', false)
/* === PROPS === */

/* === VALUE === */
const selections = toRef(props.context, 'selections')
const hasSelection = computed(() => selections.value.length > 0 && !selections.value[0].attrs?.['data-is-empty-option'])
const formattedPlaceholder = computed(() =>
  hasSelection.value ? selections.value.map(({ label }) => label).join(', ') : placeholder.value,
)
const innerValue = computed({
  get: () => {
    return multiple.value ? selections.value : selections.value[0]
  },
  set: (option: FormKitOptionsItem | FormKitOptionsItem[] | undefined) => {
    props.context.handlers.selectInput(Array.isArray(option) ? option.map(({ value }) => value) : option?.value)
  },
})
/* === VALUE === */

/* === COMPUTED === */
const isEmpty = computed(() => options.value.length === 0)
const showButtonLoading = computed(() => props.context._value && props.context.state.loading && isEmpty.value)
/* === COMPUTED === */

/* === SEARCH === */
const searchValue = toRef(props.context, 'search')
/* === SEARCH === */

/* === INFINITE SCROLL === */
const dropdownWrapper = ref()
useInfiniteScroll(dropdownWrapper, async () => {
  if (props.context.loadOnScroll) {
    await props.context.handlers.loadMore()
  }
})
/* === INFINITE SCROLL === */
</script>

<template>
  <!-- Wrapper -->
  <component
    :is="searchable ? Combobox : Listbox"
    v-slot="{ open }"
    v-model="innerValue"
    :multiple="multiple"
    :disabled="disabled"
  >
    <!-- Button Input -->
    <component
      :is="searchable ? ComboboxButton : ListboxButton"
      :class="context.classes.button"
      :data-headlessui-state="open ? 'open' : hasSelection ? 'selected' : undefined"
    >
      <!-- TODO: Add Selection Appearance slot -->
      <LLoading v-if="showButtonLoading" class="mr-1" :size="20" />
      <span :class="context.classes.buttonInner"> {{ formattedPlaceholder }} </span>
    </component>

    <!-- Dropdown Wrapper -->
    <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-if="open" ref="dropdownWrapper" :class="context.classes.dropdownWrapper">
        <!-- Search Input -->
        <ComboboxInput
          v-if="searchable"
          :class="[context.classes.input, (!isEmpty || emptyMessage) && 'border-b border-gray-100']"
          :value="searchValue"
          @change="context.handlers.search($event.target.value)"
        />

        <!-- List box -->
        <component :is="searchable ? ComboboxOptions : ListboxOptions" static :class="context.classes.listbox">
          <!-- Empty message -->
          <li v-if="isEmpty && emptyMessage" :class="context.classes.emptyMessage">
            <span :class="context.classes.emptyMessageInner">{{ emptyMessage }}</span>
          </li>
          <!-- List box item -->
          <component
            :is="searchable ? ComboboxOption : ListboxOption"
            v-for="(option, idx) in options"
            :key="idx"
            :value="option"
            :class="context.classes.listitem"
          >
            <!-- TODO: Add Option Appearance slot -->
            <span :class="context.classes.listitemInner">
              {{ option.label }}
            </span>
          </component>
          <!-- Load more -->
          <li v-if="context.state.loading" :class="context.classes.loadMore">
            <span :class="context.classes.loadMoreInner">{{ $t('general.loading') }}...</span>
          </li>
          <li v-else-if="context._hasNextPage" :class="context.classes.loadMore" @click="context.handlers.loadMore">
            <span :class="context.classes.loadMoreInner">{{ $t('general.loadMore') }}</span>
          </li>
        </component>
      </div>
    </transition>
  </component>
</template>
