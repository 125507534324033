import Keycloak, { type KeycloakConfig, type KeycloakInitOptions } from 'keycloak-js'
import { hasFailed, isAuthenticated, isPending, setProfile, setToken } from './state'
import { isNil, resolveInitConfig } from '../utils'

let $keycloak: Keycloak | undefined = undefined

export async function isTokenReady(): Promise<void> {
  return new Promise((resolve) => checkToken(resolve))
}

const checkToken = (resolve: () => void) => {
  if (!isNil($keycloak) && !isNil($keycloak.token)) {
    resolve()
  } else {
    setTimeout(() => checkToken(resolve), 500)
  }
}

export function getKeycloak(): Keycloak {
  return $keycloak!
}

export async function getToken(): Promise<string> {
  return updateToken()
}

export async function isLoggedIn(): Promise<boolean> {
  try {
    if (!$keycloak || $keycloak.authenticated) {
      return false
    }

    await updateToken()

    return true
  } catch (error) {
    return false
  }
}

export async function updateToken(): Promise<string> {
  if (!$keycloak) {
    throw new Error('Keycloak is not initialized.')
  }

  try {
    await $keycloak.updateToken(10)

    setToken($keycloak.token!)
  } catch (error) {
    hasFailed(true)

    throw new Error('Failed to refresh the token, or the session has expired')
  }
  return $keycloak.token!
}

export function createKeycloak(config: KeycloakConfig | string): Keycloak {
  $keycloak = new Keycloak(config)
  return getKeycloak()
}

export async function initKeycloak(initConfig: KeycloakInitOptions): Promise<void> {
  if (!$keycloak) {
    throw new Error('Keycloak is not initialized.')
  }

  try {
    isPending(true)

    const _isAuthenticated = await $keycloak.init(resolveInitConfig(initConfig))

    isAuthenticated(_isAuthenticated)

    if (!isNil($keycloak.token)) {
      setToken($keycloak.token as string)
      setProfile(await $keycloak.loadUserProfile())
    }

    $keycloak.onAuthRefreshSuccess = () => {
      setToken($keycloak!.token!)
      $keycloak!.loadUserProfile().then((profile) => setProfile(profile))
    }
    $keycloak.onTokenExpired = () => updateToken()
  } catch (error) {
    hasFailed(true)
    isAuthenticated(false)
    setProfile(null)
    throw new Error('Could not read access token')
  } finally {
    isPending(false)
  }
}
