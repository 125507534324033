import { LDropzoneFile, LDropzoneStatus } from '@ui/components/LDropzone'
import { nanoid } from 'nanoid'

export interface UseFilesOptions {
  onAdd?: (file: LDropzoneFile) => void
  onRemove?: (file: LDropzoneFile) => void
}

export const useFiles = (innerValue: Ref<LDropzoneFile[]>, options: UseFilesOptions = {}) => {
  const { onAdd = () => {}, onRemove = () => {} } = options

  const canBeRemoved = (file: LDropzoneFile) => file.status === LDropzoneStatus.UPLOADED || file.status === LDropzoneStatus.ERROR
  const canRemoveAll = computed(() => innerValue.value.some((file) => canBeRemoved(file)))
  const isRemovingFiles = computed(() => innerValue.value.filter((file) => file.status === LDropzoneStatus.REMOVING).length > 0)

  const createFile = async (file: File): Promise<LDropzoneFile> => {
    return {
      _id: nanoid(4),
      id: null,
      name: file.name,
      file,
      status: LDropzoneStatus.ADDED,
      progress: 0,
      url: null,
    }
  }

  const addFile = async (file: File) => {
    const _file = await createFile(file)
    innerValue.value.push(_file)
    onAdd(_file)
  }

  const removeFile = async (file: LDropzoneFile) => {
    file.status = LDropzoneStatus.REMOVING

    try {
      if (file.id) {
        await deleteAttachment(file.id)
      }
      file.status = LDropzoneStatus.REMOVED
    } catch (e) {
      console.error(e)
      file.status = LDropzoneStatus.ERROR
    } finally {
      // Remove from list anyway
      innerValue.value.splice(
        innerValue.value.findIndex((f) => f._id === file._id),
        1,
      )
      onRemove(file)
    }
  }

  const removeAllFiles = async () => {
    // Clone files list
    const files = [...innerValue.value].filter(canBeRemoved)

    for (const file of files) {
      await removeFile(file)
    }
  }

  return {
    canRemoveAll,
    isRemovingFiles,
    addFile,
    removeFile,
    removeAllFiles,
    canBeRemoved,
  }
}
