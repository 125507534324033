import * as Sentry from '@sentry/vue'
import type { NitroFetchOptions, NitroFetchRequest, TypedInternalResponse } from 'nitropack'
import { FetchError } from 'ofetch'
import { joinURL } from 'ufo'

export function fetchAuthorized<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest,
  O extends NitroFetchOptions<R> = NitroFetchOptions<R>,
>(request: R, opts: O = {} as O) {
  const { app } = useRuntimeConfig()
  const { token } = useAuth()

  return $fetch(request, {
    headers: {
      Authorization: `Bearer ${token.value}`,
      ...(opts.headers ?? {}),
    },
    baseURL: app.apiURL,
    retry: false,
    ...opts,
  }).catch((e: unknown) => {
    reportToSentry(e)

    return Promise.reject(e)
  }) as unknown as Promise<TypedInternalResponse<R, T>>
}

export function fetchBackoffice<T = unknown, O extends NitroFetchOptions<string> = NitroFetchOptions<string>>(
  request: string,
  opts: O = {} as O,
) {
  const { backofficeAPI } = useRuntimeConfig().app
  return fetchAuthorized(joinURL(backofficeAPI, request), opts) as Promise<TypedInternalResponse<string, T>>
}

export function fetchAttachments<T = unknown, O extends NitroFetchOptions<string> = NitroFetchOptions<string>>(
  request: string,
  opts: O = {} as O,
) {
  const { attachmentsAPI } = useRuntimeConfig().app
  return fetchAuthorized(joinURL(attachmentsAPI, request), opts) as Promise<TypedInternalResponse<string, T>>
}

export function fetchEHR<T = unknown, O extends NitroFetchOptions<string> = NitroFetchOptions<string>>(
  request: string,
  opts: O = {} as O,
) {
  const { ehrAPI } = useRuntimeConfig().app
  return fetchAuthorized(joinURL(ehrAPI, request), opts) as Promise<TypedInternalResponse<string, T>>
}

export function fetchAppointments<T = unknown, O extends NitroFetchOptions<string> = NitroFetchOptions<string>>(
  request: string,
  opts: O = {} as O,
) {
  const { appointmentsAPI } = useRuntimeConfig().app
  return fetchAuthorized(joinURL(appointmentsAPI, request), opts) as Promise<TypedInternalResponse<string, T>>
}

// TODO: Improve sentry integration
function reportToSentry(error: unknown) {
  console.log('Reporting to Sentry...')

  Sentry.withScope(function (scope) {
    if (error instanceof FetchError) {
      // We don't want to report 401 errors to Sentry
      if (error.statusCode === 401) {
        return
      }

      scope.setContext('request', {
        url: error.request,
        status: error.statusCode,
        message: error.statusMessage,
      })

      if (error.response) {
        scope.setContext('response', {
          headers: error.response?.headers,
          data: error.data ? JSON.stringify(error.data) : undefined,
        })
      }

      Sentry.captureMessage(error.statusMessage ?? 'Something went wrong.')
    } else if (error instanceof Error) {
      Sentry.captureMessage(error.message)
    } else {
      Sentry.captureMessage(typeof error === 'string' ? error : 'Something went wrong.')
    }
  })
}
