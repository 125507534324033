import { createMessage } from '@formkit/core'
import type { FormKitNode } from '@formkit/core'

export function loadingPlugin(node: FormKitNode) {
  node.on('created', () => {
    // We should not change `disabled` if it is being modified externally.
    const autoDisable = node.props.disabled === undefined && node.props.submitBehavior !== 'live'

    // Initialize internal counter
    if (node.context) {
      node.context._l = 0
    }

    node.disturbLoading = () => {
      if (!node.context) return

      if (node.context._l <= 0) {
        node.context.isLoading = true
        node.emit('loading', true, false)
        node.context.loading = new Promise((resolve) => {
          if (node.context) {
            node.context._resolveLoading = resolve
          }
        })
      }
      node.context._l++
    }

    node.calmLoading = () => {
      if (!node.context) return
      if (node.context._l > 0) node.context._l--
      if (node.context._l === 0) {
        node.context.isLoading = false
        node.emit('loading', false, false)
        if (node.context._resolveLoading) node.context._resolveLoading(node.context.value)
      }
    }

    node.on('loading', ({ payload }) => {
      if (payload) {
        // Set `disabled` and `loading` states to `true`
        if (autoDisable) node.props.disabled = true
        node.store.set(
          createMessage({
            key: 'loading',
            value: true,
            visible: false,
          }),
        )
      } else {
        // Set `disabled` and `loading` states to `false`
        if (autoDisable) node.props.disabled = false
        node.store.remove('loading')
      }
    })
  })
}
