import type { CreateAbility, MongoAbility } from '@casl/ability'
import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { isGeneralTeam, isHealthTeam, isOpsTeam } from '../utils'

import type { Anamnesis } from '@anamnesis'
import type { Appointment } from '@appointments'
import type { User } from '@auth'
import type { BodyPicture } from '@body-pictures'
import type { Customer } from '@customers'
import type { Exam } from '@exams'
import type { HabitTracker } from '@habit-tracker'
import type { History } from '@history'
import type { Impedance } from '@impedances'
import type { MealPlan } from '@meal-plans'
import type { CustomerMealPlan } from '@meal-plans-v2'
import type { Measure } from '@measures'
import type { Prescription } from '@meds'
import type { Register } from '@registers'
import type { Staff } from '@staffs'

export type Actions = 'create' | 'read' | 'update' | 'delete'
export type Subjects =
  | Customer
  | Staff
  | Appointment
  | Register
  | Impedance
  | Measure
  | HabitTracker
  | MealPlan
  | CustomerMealPlan
  | History
  | Prescription
  | Exam
  | BodyPicture
  | Anamnesis
  | Notification
  | 'Customer'
  | 'Staff'
  | 'Appointment'
  | 'Register'
  | 'Impedance'
  | 'Measure'
  | 'HabitTracker'
  | 'MealPlan'
  | 'CustomerMealPlan'
  | 'History'
  | 'Med'
  | 'Exam'
  | 'BodyPicture'
  | 'Anamnesis'
  | 'Notification'
  | 'Health'
  | 'OPS'

export type AppAbility = MongoAbility<[Actions, Subjects]>
const createAppAbility = createMongoAbility as CreateAbility<AppAbility>
export const defineAbilityFor = (user: User | null) => {
  const { can, build } = new AbilityBuilder(createAppAbility)

  // Not logged in
  if (!user) {
    return build()
  }

  // Everyone can read customer and staff
  can('read', ['Customer', 'Staff'])

  // Everyone can access health and ops
  can('read', ['Health'])
  can('read', ['OPS'])

  // HEALTH TEAM
  if (isHealthTeam(user)) {
    can(
      ['read', 'create', 'update'],
      [
        'Appointment',
        'Register',
        'Impedance',
        'Measure',
        'HabitTracker',
        'MealPlan',
        'History',
        'Prescription',
        'Exam',
        'BodyPicture',
        'Notification',
      ],
    )
  }

  // OPS TEAM
  if (isHealthTeam(user) || isOpsTeam(user) || isGeneralTeam(user)) {
    can(['create', 'update'], ['Customer', 'Staff'])
  }

  return build()
}
