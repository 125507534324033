import type { FormKitNode } from '@formkit/core'
import { undefine, eq } from '@formkit/utils'
import type { FormKitOptionsItem, FormKitOptionsList } from '@formkit/inputs'
import { optionValue, shouldSelect } from './options'

/**
 * Checks if a the given option should have the selected attribute.
 * @param node - The node being evaluated.
 * @param option - The option value to check
 * @returns
 * @public
 */
function isSelected(node: FormKitNode, option: FormKitOptionsItem) {
  // Here we trick reactivity (if at play) to watch this function.
  node.context?.value
  const optionValue = '__original' in option ? option.__original : option.value
  function hasNoNullOption() {
    return !node.props.options.some(
      (option: FormKitOptionsItem) => ('__original' in option ? option.__original : option.value) === null,
    )
  }

  return Array.isArray(node._value)
    ? node._value.some((optionA) => shouldSelect(optionA, optionValue))
    : (node._value === undefined || (node._value === null && hasNoNullOption())) &&
        option.attrs &&
        option.attrs['data-is-empty-option']
      ? true
      : shouldSelect(optionValue, node._value)
}

/**
 * Defers the change event till after the next cycle.
 * @param node - The node being evaluated.
 * @param e - The change event.
 */
async function deferChange(node: FormKitNode, e: Event) {
  if (typeof node.props.attrs?.onChange === 'function') {
    await new Promise((r) => setTimeout(r, 0))
    await node.settled
    node.props.attrs.onChange(e)
  }
}

/**
 * Select the correct values.
 * @param e - The input event emitted by the select.
 */
async function selectInput(node: FormKitNode, value: string | string[]) {
  const _value = undefine(node.props.attrs?.multiple)
    ? (value as string[]).map((o) => optionValue(node.props.options, o))
    : optionValue(node.props.options, value as string)
  await node.input(_value)
  if (node.context) {
    if (node.props.autocompleteOnSelect && !node.props.multiple) {
      node.context.search = node.context.selections[0].label ?? node.context.search
    }

    if (node.props.clearOptionsOnSelect) {
      node.props.options = [...node.context.selections]
    }
  }
}

/**
 * Appends a emptyOption to the options list.
 * @param options - An options list
 * @param emptyOption - A emptyOption string to append
 * @returns
 */
function applyEmptyOption(options: FormKitOptionsList, emptyOption: string) {
  if (!options.some((option) => option.attrs && option.attrs['data-is-empty-option'])) {
    return [
      {
        label: emptyOption,
        value: '',
        attrs: {
          hidden: true,
          disabled: true,
          'data-is-empty-option': 'true',
        },
      },
      ...options,
    ]
  }
  return options
}

function applyInitialOption(options: FormKitOptionsList, initial: FormKitOptionsItem) {
  if (!options.some((option) => option.value === initial.value)) {
    return [initial, ...options]
  }
  return options
}

/**
 * Converts the options prop to usable values.
 * @param node - A formkit node.
 * @public
 */
export default function select(node: FormKitNode): void {
  // Set the initial value of a multi-input
  node.on('created', () => {
    const isMultiple = undefine(node.props.attrs?.multiple)

    if (node.props.initialOption) {
      node.hook.prop(({ prop, value }, next) => {
        if (prop === 'options' && value.length > 0) {
          value = applyInitialOption(value, node.props.initialOption)
        }
        return next({ prop, value })
      })
      if (node.props.options.length > 0) {
        node.props.options = applyInitialOption(node.props.options, node.props.initialOption)
      }
    }
    if (!isMultiple && node.props.emptyOption && Array.isArray(node.props.options)) {
      node.hook.prop(({ prop, value }, next) => {
        if (prop === 'options' && value.length > 0) {
          value = applyEmptyOption(value, node.props.emptyOption)
        }
        return next({ prop, value })
      })
      if (node.props.options.length > 0) {
        node.props.options = applyEmptyOption(node.props.options, node.props.emptyOption)
      }
    }
    if (isMultiple) {
      if (node.value === undefined) {
        node.input([], false)
      }
    } else if (node.context && !node.context.options) {
      // If this input is (probably) using the default slot, we need to add a
      // "value" attribute to get bound
      node.props.attrs = Object.assign({}, node.props.attrs, {
        value: node._value,
      })
      node.on('input', ({ payload }) => {
        node.props.attrs = Object.assign({}, node.props.attrs, {
          value: payload,
        })
      })
    }
    if (node.context) {
      node.context.selections = computed(() =>
        (node.context?.options ?? []).filter((option) => node.context?.fns.isSelected(option)),
      )
    }
    if (node.context?.handlers) {
      node.context.handlers.selectInput = selectInput.bind(null, node)
      node.context.handlers.onChange = deferChange.bind(null, node)
    }
    if (node.context?.fns) {
      node.context.fns.isSelected = isSelected.bind(null, node)
      node.context.fns.showEmptyOption = (value: unknown, emptyOption) => {
        if (!Array.isArray(node.props.options)) return false
        const hasMatchingValue = node.props.options.some((option: FormKitOptionsItem) => {
          if (option.attrs && 'data-is-empty-option' in option.attrs) return false
          const optionValue = '__original' in option ? option.__original : option.value
          return eq(value, optionValue)
        })
        return emptyOption && !hasMatchingValue ? true : undefined
      }
    }
  })
}
