<script setup lang="ts">
import VueMultiselect from 'vue-multiselect'
import type { PropType } from 'vue'
import type { LMultiSelectContextProp } from './types'

const props = defineProps({
  context: {
    type: Object as PropType<LMultiSelectContextProp>,
    required: true,
  },
})

const { t } = useI18n()

const isBoolDefined = (boolean: unknown) => boolean !== undefined && boolean !== null && boolean !== false

// Check if options are objects.
const isObjectOptions = computed(
  () => Array.isArray(props.context.options) && props.context.options.every((item) => isObject(item)),
)

const innerTrackBy = computed(() => (props.context.trackBy ? props.context.trackBy : isObjectOptions.value ? 'value' : undefined))
const innerLabelBy = computed(() => (props.context.labelBy ? props.context.labelBy : isObjectOptions.value ? 'label' : undefined))
const innerSearchable = computed(() => {
  if (props.context.searchable) return props.context.searchable

  return isBoolDefined(props.context.multiple) || false
})
const innerAllowEmpty = computed(() => fallback<boolean>(props.context.allowEmpty, isBoolDefined(props.context.multiple)))
const innerTagPlaceholder = computed(() => fallback<string>(props.context.tagPlaceholder, t('inputs.multiselect.tagPlaceholder')))
const innerGroupValues = computed(() => (props.context.group ? fallback(props.context.groupValues, 'options') : undefined))
const innerGroupLabel = computed(() => (props.context.group ? fallback(props.context.groupLabel, 'group') : undefined))
const innerGroupSelect = computed(() => (props.context.group && props.context.multiple ? props.context.groupSelect : undefined))
const innerSelectLabel = computed(() => fallback<string>(props.context.selectLabel, t('inputs.multiselect.selectLabel')))
const innerSelectGroupLabel = computed(() =>
  fallback<string>(props.context.selectGroupLabel, t('inputs.multiselect.selectGroupLabel')),
)
const innerSelectedLabel = computed(() => fallback<string>(props.context.selectedLabel, t('inputs.multiselect.selectedLabel')))
const innerDeselectLabel = computed(() => fallback<string>(props.context.deselectLabel, t('inputs.multiselect.deselectLabel')))
const innerDeselectGroupLabel = computed(() =>
  fallback<string>(props.context.deselectGroupLabel, t('inputs.multiselect.deselectGroupLabel')),
)
const innerShowLabels = computed(() => fallback<boolean>(props.context.showLabels, false))
const innerLimitText = computed(() =>
  fallback<(count: number) => string>(props.context.limitText, (count: number) => t('inputs.multiselect.limitText', { count })),
)

const getValue = (option: any): unknown => {
  return isPlainObject(option) ? option[innerTrackBy.value!] : option
}
const findItemByValue = (value: unknown) => {
  let options: any = props.context.options

  if (props.context.group) {
    options = options.flatMap((group) => group[innerGroupValues.value!])
  }

  return options.find((option) => {
    const valueGetted = getValue(option)
    if (valueGetted === value) return true
    if (value && typeof value === 'object' && valueGetted === value.value) return true

    return false
  })
}

// const innerValue = ref()

// const handleInput = (value: unknown) => {
//   innerValue.value = value

//   if (isArray(value)) {
//     value = value.map((option) => getValue(option))
//   } else if (isPlainObject(value)) {
//     value = getValue(value)
//   }

//   props.context.node.input(value)
// }

// Watch for changes in external value.
// watch(
//   () => props.context._value,
//   (value: unknown) => {
//     value = isArray(value) ? value.map(findItemByValue) : findItemByValue(value)

//     innerValue.value = value
//   },
//   { immediate: true }
// )

// Watch for changes in inner value
// watch(innerValue, (value: unknown) => {
//   if (isArray(value)) {
//     value = value.map((option) => getValue(option))
//   } else if (isPlainObject(value)) {
//     value = getValue(value)
//   }

//   props.context.node.input(value)
// })

const innerValue = computed({
  // getter
  get() {
    const value = props.context._value
    return isArray(value) ? value.map(findItemByValue) : findItemByValue(value)
  },
  // setter
  set(newValue) {
    if (isArray(newValue)) {
      newValue = newValue.map((option) => getValue(option))
    } else if (isPlainObject(newValue)) {
      newValue = getValue(newValue)
    }

    props.context.node.input(newValue)
  },
})

/*
  Events.

  - Select	(selectedOption, id)	@select	Emitted after selecting an option
  - Remove	(removedOption, id)	@remove	Emitted after removing an option
  - SearchChange	(searchQuery, id)	@search-change	Emitted after the search query changes
  - Tag	(searchQuery, id)	@tag	Emitted after user attempts to add a tag
  - Open	(id)	@open	Emitted when the dropdown opens. Useful for detecting when touched.
  - Close	(value, id)	@close	Emitted when the dropdown closes
*/
const onSelect = (selectedOption: any[], id: string | number) => {
  props.context.node.emit('select', { selectedOption, id })
}

const onRemove = (removedOption: any[], id: string | number) => {
  props.context.node.emit('remove', { removedOption, id })
}

const onSearchChange = (query: string, id: string | number) => {
  props.context.node.emit('searchChange', { query, id })
}

const onTag = (searchQuery: string, id: string | number) => {
  props.context.node.emit('tag', { searchQuery, id })
}

const onOpen = (id: string | number) => {
  props.context.node.emit('open', { id })
}

const onClose = (value: unknown, id: string | number) => {
  props.context.node.emit('close', { value, id })
}
</script>

<template>
  <div>
    <VueMultiselect
      v-model="innerValue"
      :class="{
        'multiselect-group': !!context.group,
      }"
      :options="context.options"
      :multiple="context.multiple"
      :track-by="innerTrackBy"
      :label="innerLabelBy"
      :searchable="innerSearchable"
      :clear-on-select="context.clearOnSelect"
      :hide-selected="context.hideSelected"
      :placeholder="context.placeholder"
      :allow-empty="innerAllowEmpty"
      :reset-after="context.resetAfter"
      :close-on-select="context.closeOnSelect"
      :custom-label="context.customLabel"
      :taggable="context.taggable"
      :tag-placeholder="innerTagPlaceholder"
      :tag-position="context.tagPosition"
      :max="context.max"
      :options-limit="context.optionsLimit"
      :group-values="innerGroupValues"
      :group-label="innerGroupLabel"
      :group-select="innerGroupSelect"
      :block-keys="context.blockKeys"
      :internal-search="context.internalSearch"
      :preserve-search="context.preserveSearch"
      :preselect-first="context.preselectFirst"
      :select-label="innerSelectLabel"
      :select-group-label="innerSelectGroupLabel"
      :selected-label="innerSelectedLabel"
      :deselect-label="innerDeselectLabel"
      :deselect-group-label="innerDeselectGroupLabel"
      :show-labels="innerShowLabels"
      :limit="context.limit"
      :limit-text="innerLimitText"
      :loading="context.state.loading"
      :disabled="context.disabled"
      :max-height="context.maxHeight"
      :open-direction="context.openDirection"
      :show-no-results="context.showNoResults"
      :tab-index="context.tabIndex"
      :show-pointer="context.showPointer"
      :options-height="context.optionsHeight"
      @select="onSelect"
      @remove="onRemove"
      @search-change="onSearchChange"
      @tag="onTag"
      @open="onOpen"
      @close="onClose"
    >
      <template #maxElements>
        {{ $t('inputs.multiselect.maxText', { max: props.context.max }) }}
      </template>

      <!-- Slots -->
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
    </VueMultiselect>
  </div>
</template>

<style lang="postcss">
/* stylelint-disable */
.l-multiselect {
  fieldset[disabled] .multiselect {
    @apply pointer-events-none;
  }

  &[data-invalid] .multiselect {
    .multiselect__tags,
    .multiselect__input {
      @apply bg-red-50;
    }

    .multiselect__tags,
    .multiselect__content-wrapper {
      @apply border-red-500  ring-red-500;
    }

    .multiselect__tag {
      @apply bg-red-900 text-white;
    }

    .multiselect__placeholder,
    .multiselect__input::placeholder {
      @apply text-red-700;
    }

    &:not(.multiselect--active) {
      @apply text-red-900;

      .multiselect__content-wrapper {
        @apply bg-red-50;
      }
    }
  }

  .multiselect__spinner {
    @apply absolute right-[1px] top-[1px] block h-[42px] w-12 rounded-lg bg-inherit sm:h-[40px];

    &::before,
    &::after {
      @apply absolute left-[50%] top-[50%] -ml-2 -mt-2 mb-0 mr-0 h-4 w-4 rounded-full border-2 border-solid border-x-transparent border-b-transparent border-t-purple-900 shadow-[0_0_0_1px_transparent] content-[""];
    }

    &::before {
      @apply animate-spin;
    }

    &::after {
      @apply animate-spin;
    }
  }

  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    @apply opacity-100 transition-opacity duration-300 ease-in-out;
  }

  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    @apply opacity-0;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    @apply touch-manipulation sm:text-sm;

    font-family: inherit;
  }

  .multiselect {
    @apply relative box-content block min-h-[44px] w-full text-left text-purple-900 sm:min-h-[42px];

    * {
      @apply box-border;
    }

    &:focus {
      @apply outline-none;
    }
  }

  .multiselect--disabled {
    @apply pointer-events-none;

    .multiselect__tags {
      @apply bg-gray-100;
    }
  }

  .multiselect--active {
    @apply z-50;

    .multiselect__tags {
      @apply border-purple-900 ring-1 ring-purple-900;
    }

    .multiselect__select {
      transform: rotateZ(180deg);
    }
  }

  /* TODO input placeholder padding */

  .multiselect__input,
  .multiselect__single {
    @apply relative mb-2 box-border inline-block min-h-[20px] w-full rounded-lg border-none bg-transparent p-0 align-top leading-5 transition duration-100;
  }

  /* TODO: Placeholder color */
  .multiselect__input::placeholder {
    @apply text-gray-400;
  }

  .multiselect__tag ~ {
    .multiselect__input,
    .multiselect__single {
      @apply w-auto;
    }
  }

  .multiselect__input:hover,
  .multiselect__single:hover {
    @apply border-gray-300;
  }

  .multiselect__input:focus {
    @apply border-gray-300 outline-none ring-0;
  }

  /* TODO: Single select padding */
  .multiselect__single {
    &:focus {
      @apply border-gray-400 outline-none;
    }

    @apply mb-2;
  }

  .multiselect__tags-wrap {
    @apply inline;
  }

  .multiselect__tags {
    @apply block min-h-[44px] rounded-lg border border-solid border-gray-300 bg-gray-50 pb-0 pl-2.5 pr-10 pt-[11px] transition duration-100 sm:min-h-[42px] sm:pt-2.5 sm:text-sm;
  }

  .multiselect__tag {
    @apply relative mb-[4px] mr-3 inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-md bg-purple-900 py-[3px] pl-3 pr-7 leading-none text-white transition duration-100;
  }

  .multiselect__tag-icon {
    @apply absolute bottom-0 right-0 top-0 ml-2 w-6 cursor-pointer rounded-lg text-center font-bold leading-[21px] transition-all duration-100;
    font-style: initial;

    &::after {
      @apply text-sm text-white;

      content: '×';
    }

    &:focus::after,
    &:hover::after {
      @apply text-white;
    }
  }

  .multiselect__current {
    @apply m-0 box-border block min-h-[42px] cursor-pointer overflow-hidden whitespace-nowrap rounded-lg border border-solid border-gray-100 px-3 pb-0 pt-2 leading-4 no-underline sm:min-h-[40px];
  }

  .multiselect__select {
    @apply absolute right-[1px] top-[1px] m-0 box-border block h-[42px] w-10 cursor-pointer rounded-lg bg-inherit px-2 py-1 text-center leading-4 no-underline transition-transform duration-100 sm:h-[40px];

    &::before {
      @apply relative right-0 top-[65%] mt-1 border-x-[5px] border-b-0 border-t-[5px] border-solid border-transparent border-t-gray-400 text-gray-400 content-[""];
    }
  }

  /* TODO: Change placholder padding */
  .multiselect__placeholder {
    @apply mb-2 inline-block p-0 align-top leading-5 text-gray-400 transition duration-100;
  }

  .multiselect--active .multiselect__placeholder {
    @apply hidden;
  }

  .multiselect__content-wrapper {
    @apply absolute z-50 my-2 block max-h-[240px] w-full overflow-auto rounded-lg bg-white drop-shadow-lg;

    -webkit-overflow-scrolling: touch;
  }

  .multiselect__content {
    @apply m-0 inline-block min-w-full list-none p-0 align-top;
  }

  .multiselect--above .multiselect__content-wrapper {
    @apply bottom-full rounded-lg;
  }

  .multiselect__content::-webkit-scrollbar {
    @apply hidden;
  }

  .multiselect__element {
    @apply block;
  }

  .multiselect__option {
    @apply relative block min-h-[42px] cursor-pointer whitespace-nowrap p-3 align-middle normal-case leading-4 no-underline transition duration-100 sm:min-h-[40px];

    &::after {
      @apply absolute right-0 top-0 pl-5 pr-3 text-sm leading-10;
    }
  }

  :not(.multiselect-group) .multiselect__option--highlight {
    @apply text-primary bg-gray-200 outline-none;

    &::after {
      @apply bg-purple-900 text-white content-[attr(data-select)];
    }
  }

  .multiselect-group .multiselect__option--highlight {
    @apply bg-roxo-liti-400 text-white outline-none;

    &::after {
      @apply bg-roxo-liti-400 text-white content-[attr(data-select)];
    }
  }

  .multiselect__option--selected {
    @apply bg-primary text-white;

    &::after {
      @apply text-gray-300 content-[attr(data-select)];
    }

    &.multiselect__option--highlight {
      @apply bg-primary text-white;

      &::after {
        @apply bg-primary text-white content-[attr(data-select)];
      }
    }
  }

  .multiselect--disabled {
    .multiselect__current,
    .multiselect__select {
      @apply text-gray-400;
    }
  }

  .multiselect__option--disabled {
    @apply pointer-events-none cursor-text !bg-gray-200 !text-gray-400;
  }

  .multiselect__option--group {
    @apply bg-gray-200 text-purple-900;

    &.multiselect__option--highlight {
      @apply bg-purple-900 text-white;

      &::after {
        @apply bg-purple-900;
      }
    }
  }

  .multiselect__option--disabled.multiselect__option--highlight {
    @apply bg-gray-200;
  }

  .multiselect__option--group-selected.multiselect__option--highlight {
    @apply bg-red-400 text-white;

    &::after {
      @apply bg-red-400 text-white content-[attr(data-select)];
    }
  }

  /* Disable transition */
  /*.multiselect-enter-active,
  .multiselect-leave-active {
    @apply transition-all;
  }*/

  .multiselect-enter,
  .multiselect-leave-active {
    @apply opacity-0;
  }

  .multiselect__strong {
    @apply mb-2 inline-block align-top leading-5;
  }

  *[dir='rtl'] {
    .multiselect {
      @apply text-right;
    }

    .multiselect__select {
      @apply left-[1px] right-auto;
    }

    .multiselect__tags {
      @apply pb-0 pl-10 pr-2 pt-2;
    }

    .multiselect__content {
      @apply text-right;
    }

    .multiselect__option::after {
      @apply left-0 right-auto;
    }

    .multiselect__clear {
      @apply left-3 right-auto;
    }

    .multiselect__spinner {
      @apply left-[1px] right-auto;
    }
  }
}
/* stylelint-enable */
</style>
