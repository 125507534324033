import { PositionEnum } from '@staffs'

export const HEALTH_POSITIONS = [
  PositionEnum.DOCTOR,
  PositionEnum.BE_SCI,
  PositionEnum.NUTRITIONIST,
  PositionEnum.PHYSICAL_EDUCATOR,
]
export const OPS_POSITIONS = [PositionEnum.OPERATIONS]
export const GENERAL_POSITIONS = [PositionEnum.GENERAL]
