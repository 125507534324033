import { createSection } from '@formkit/inputs'
import { markRaw } from 'vue'
import Selectbox from '../Selectbox.vue'

/**
 * Input section used by selects
 *
 * @public
 */
export const selectInput = createSection('input', () => ({
  $cmp: markRaw(Selectbox) as unknown as string,
  bind: '$attrs',
  props: {
    context: '$node.context',
  },
  attrs: {
    id: '$id',
    disabled: '$disabled',
    class: '$classes.input',
  },
}))
