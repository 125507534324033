<script lang="ts" setup>
import { ModalsContainer } from 'vue-final-modal'
import ConfirmDialog from 'primevue/confirmdialog'
import { Notivue, Notification } from 'notivue'
import { notivueTheme } from '@ui'

const route = useRoute()
const { t, locale } = useI18n()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const title = computed<string | undefined>(() => (route.meta.title ? t(route.meta.title as string) : 'LOS'))

// Set FormKit locale
const config = inject(Symbol.for('FormKitConfig'))!
config.locale = locale.value === 'pt-BR' ? 'pt' : locale.value
useDayjs().locale(locale.value.toLocaleLowerCase())
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <Title v-if="title">{{ title }}</Title>
      <Title v-else>LOS</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>

      <Meta http-equiv="content-type" content="text/html;charset=utf-8" />
      <Meta charset="utf-8" />
      <Meta http-equiv="x-ua-compatible" content="IE=Edge" />

      <Link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <Link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <Link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <Link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <Link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
      <Link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
      <Link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
      <Link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
      <Link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
      <Link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
      <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <Link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
      <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <!-- <link rel="manifest" href="/manifest.json" /> -->
      <Meta name="msapplication-TileColor" content="#ffffff" />
      <Meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <Meta name="theme-color" content="#ffffff" />
      <Meta name="background-color" content="#ffffff" />

      <Meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, viewport-fit=cover" />
      <Meta name="mobile-web-app-capable" content="yes" />
      <Meta name="apple-mobile-web-app-capable" content="yes" />

      <!-- possible content values: default, black or black-translucent -->
      <Meta name="apple-mobile-web-app-status-bar-style" content="light-content" />

      <Link
        href="/splashscreens/iphone5_splash.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <Link
        href="/splashscreens/iphone6_splash.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <Link
        href="/splashscreens/iphoneplus_splash.png"
        media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <Link
        href="/splashscreens/iphonex_splash.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <Link
        href="/splashscreens/iphonexr_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <Link
        href="/splashscreens/iphonexsmax_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
    </Head>

    <Body>
      <NuxtLoadingIndicator :color="false" />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>

      <ConfirmDialog :draggable="false" append-to="body" class="p-component-overlay-forced" />
      <ModalsContainer />

      <Notivue v-slot="item">
        <Notification :item="item" :theme="notivueTheme" />
      </Notivue>
    </Body>
  </Html>
</template>

<style lang="postcss">
:root {
  --nv-gap: 2rem;
  --nv-z: 9999;
}

.nuxt-loading-indicator {
  @apply bg-gradient-to-r from-[#3F0075] via-[#6C43E8] to-[#3F0075]/0;
}

.p-dialog-mask.p-component-overlay {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0 0 0 / 60%);
  }
}
</style>
