import { datepicker as _datepicker, type FormKitProInput } from '@formkit/pro'
import type { FormKitNode } from '@formkit/core'

/**
 * Input definition for a form.
 * @public
 */
export const datepicker: FormKitProInput = {
  ..._datepicker,
  features: [...(_datepicker.features ?? []), panelPosition],
}

function panelPosition(node: FormKitNode) {
  node.on('created', () => {
    watch(
      () => node.context?.expanded,
      async (value) => {
        if (value === true) {
          await nextTick()

          if (!node.context) return

          const elInput = document.getElementById(node.context.id)
          const elPanels = document.getElementById(node.context.id + '_panels')

          // If the input or the panels are not found, we don't do anything.
          if (!elInput || !elPanels) {
            return
          }

          const { top } = calculatePositionSimple(elInput, elPanels)

          if (top) {
            elPanels.style.top = top
          }

          // const { top, left, right } = calculatePositionComplex(elInput, elPanels)
          //
          // elPanels.style.top = top
          // elPanels.style.left = left
          // elPanels.style.right = right
        }
      },
    )
  })
}

// Function to calculate panel position based on available space on screen
function calculatePositionSimple(button: HTMLElement, panel: HTMLElement) {
  const buttonRect = button.getBoundingClientRect()
  const spaceBelow = window.innerHeight - buttonRect.bottom

  const style: { top?: string } = {}

  if (panel.offsetHeight > spaceBelow) {
    style.top = `calc(-${panel.offsetHeight}px - 0.5em)`
  }

  return style
}

/*
function calculatePositionComplex(button: HTMLElement, panel: HTMLElement) {
  const buttonRect = button.getBoundingClientRect()
  const panelRect = panel.getBoundingClientRect()
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight
  const spaceBelow = screenHeight - buttonRect.bottom
  const spaceAbove = buttonRect.top
  const spaceRight = screenWidth - buttonRect.right
  const spaceLeft = buttonRect.left
  const margin = '0.5rem'
  // Ordered by priority
  const positions = [
    {
      name: 'bottom-left',
      top: `calc(100% + ${margin})`,
      left: buttonRect.left,
      maxWidth: spaceRight,
      maxHeight: spaceBelow,
    },
    {
      name: 'bottom-right',
      top: `calc(100% + ${margin})`,
      right: 0,
      maxWidth: spaceLeft,
      maxHeight: spaceBelow,
    },
    {
      name: 'top-left',
      top: `calc(-${panel.offsetHeight}px - ${margin})`,
      left: 0,
      maxWidth: spaceRight,
      maxHeight: spaceAbove,
    },
    {
      name: 'top-right',
      top: `calc(-${panel.offsetHeight}px - ${margin})`,
      right: 0,
      maxWidth: spaceLeft,
      maxHeight: spaceAbove,
    },
  ]
  // We reverse the array to preserve the order of priority
  return positions.reverse().reduce((prev, curr) => {
    const fitsWidth = panelRect.width <= curr.maxWidth
    const fitsHeight = panelRect.height <= curr.maxHeight

    if (fitsWidth && fitsHeight) {
      console.log(curr)
      return curr
    }

    return prev
  }, positions[0])
}
*/
