import type { Ref, ToRefs } from 'vue'
import { computed, useSlots } from 'vue'
import classNames from 'classnames'
import { LButtonProps } from '../types'
import type { LButtonSize, LButtonVariant } from '../types'

export interface ButtonClassMap<T extends string> {
  default: Record<T, string>
  hover: Record<T, string>
  loading: Record<T, string>
  icon: Record<T, string>
}

// https://www.figma.com/file/O6MZX87zBUoJBKmPtAwtfe/App---Design-System?type=design&node-id=1003-2616&mode=design&t=vMQOp7zH7mdB6fJ5-0
const buttonColorClasses: ButtonClassMap<LButtonVariant> = {
  default: {
    primary:
      'bg-liti-light-primary dark:bg-liti-dark-primary focus:ring-liti-light-primary dark:focus:ring-liti-dark-primary text-liti-light-text-button dark:text-liti-dark-text-button',
    secondary:
      'border border-liti-light-primary bg-liti-light-background-2 text-liti-light-primary focus:ring-liti-light-primary dark:border-liti-dark-primary dark:bg-liti-dark-background-2 dark:text-liti-dark-primary dark:focus:ring-liti-dark-primary disabled:border-none disabled:text-liti-light-text-button',
    link: 'bg-transparent focus:ring-primary-focus',
    green: 'text-white bg-green-700 focus:ring-green-300 dark:bg-green-600 dark:focus:ring-green-800',
    red: 'text-white bg-pink-700 focus:ring-pink-300 dark:bg-pink-600 dark:focus:ring-pink-900',
    'outline-critical':
      'border border-liti-light-critical text-liti-light-critical bg-liti-light-background-2 dark:border-liti-dark-critical dark:text-liti-dark-critical dark:bg-liti-dark-background-2',
    orange: 'text-white bg-orange-700 focus:ring-orange-300 dark:bg-orange-600 dark:focus:ring-orange-900',
    yellow: 'text-purple-900 bg-yellow-500 focus:ring-yellow-300 dark:focus:ring-yellow-900',
    purple: 'text-white bg-purple-700 focus:ring-purple-300 dark:bg-purple-600 dark:focus:ring-purple-900',
    blue: 'text-white bg-blue-700 focus:ring-blue-300 dark:bg-blue-600 dark:focus:ring-blue-800',
    none: '',
  },
  hover: {
    primary: 'hover:bg-liti-light-primary-hover dark:bg-liti-dark-primary-hover',
    secondary: 'hover:bg-liti-light-detail dark:hover:bg-liti-dark-detail',
    link: '',
    green: 'hover:bg-green-800 dark:hover:bg-green-700',
    red: 'hover:bg-pink-800 dark:hover:bg-pink-700',
    'outline-critical': 'hover:bg-liti-light-detail dark:hover:bg-liti-dark-detail',
    orange: 'hover:bg-orange-800 dark:hover:bg-orange-700',
    yellow: 'hover:bg-yellow-600',
    purple: 'hover:bg-purple-800 dark:hover:bg-purple-700',
    blue: 'hover:bg-blue-800 dark:hover:bg-blue-700',
    none: '',
  },
  loading: {
    primary: 'fill-purple-800',
    secondary: 'fill-purple-900 dark:fill-gray-600',
    link: 'fill-purple-900',
    green: 'fill-green-500 dark:fill-green-400',
    red: 'fill-pink-500 dark:fill-pink-400',
    'outline-critical': '',
    orange: 'fill-orange-500 dark:fill-orange-400',
    yellow: 'fill-yellow-500',
    purple: 'fill-purple-500 dark:fill-purple-400',
    blue: 'fill-blue-500 dark:fill-blue-400',
    none: '',
  },
  icon: {
    primary: 'text-white',
    secondary: 'text-purple-900 dark:text-white',
    link: 'text-purple-900',
    green: 'text-white',
    red: 'text-white',
    'outline-critical': '',
    orange: 'text-white',
    yellow: 'text-purple-900',
    purple: 'text-white',
    blue: 'text-white',
    none: '',
  },
}

const buttonSizeClasses: Record<LButtonSize, string> = {
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-3 py-1.5',
  md: 'text-base px-4 py-2',
  lg: 'text-base px-5 py-2.5',
  xl: 'text-base px-6 py-3',
}

const buttonSquareSizeClasses: Record<LButtonSize, string> = {
  xs: 'text-xs p-1',
  sm: 'text-sm p-1.5',
  md: 'text-base p-2',
  lg: 'text-base p-2.5',
  xl: 'text-base p-3',
}

const buttonSizeRoundedClasses: Record<LButtonSize, string> = {
  xs: 'rounded-md',
  sm: 'rounded-md',
  md: 'rounded-lg',
  lg: 'rounded-lg',
  xl: 'rounded-lg',
}

const buttonIconClasses: Record<any, string> = {
  prefix: '',
  suffix: '',
}

export interface UseButtonClassesInstance {
  wrapperClasses: Ref<string>
  textClasses: Ref<string>
  iconSpanClasses: Ref<string>
  loadingClasses: Ref<string>
  iconClasses: Ref<string>
}

export function useButtonClasses(props: ToRefs<LButtonProps>): UseButtonClassesInstance {
  const slots = useSlots()
  const hasText = computed(() => !!slots.default)

  const bindClasses = computed(() => {
    // Variables
    const variant = unref(props.variant)
    const size = unref(props.size)
    const isDisabled = unref(props.loading) || unref(props.disabled)
    const isRounded = unref(props.rounded)
    const isSquare = unref(props.square)
    const fullWith = unref(props.fullWidth)

    // Classes
    const colorClass = buttonColorClasses.default[variant]
    const hoverClass = !isDisabled ? buttonColorClasses.hover[variant] : ''
    const disabledClass = isDisabled ? 'cursor-not-allowed bg-liti-light-primary-disabled dark:bg-liti-dark-primary-disabled' : ''
    const roundedClass = isRounded ? 'rounded-full' : buttonSizeRoundedClasses[size]
    const widthClass = fullWith ? 'w-full' : ''
    const sizeClass = !hasText.value || isSquare || isRounded ? buttonSquareSizeClasses[size] : buttonSizeClasses[size]
    let iconOnlyClass = ''

    if (!hasText.value) {
      iconOnlyClass = 'justify-center'
      iconOnlyClass += variant === 'link' ? ' hover:bg-gray-100 dark:hover:bg-gray-600' : ''
    }

    return classNames(
      'group inline-flex items-center text-center font-normal transition duration-100 focus:outline-none focus:ring-2',
      colorClass,
      hoverClass,
      disabledClass,
      roundedClass,
      widthClass,
      sizeClass,
      iconOnlyClass,
    )
  })

  const textClasses = computed(() => {
    const decoration = props.variant.value === 'link' ? 'hover:underline' : ''
    return classNames('flex-auto', decoration)
  })

  const iconSpanClasses = computed(() => {
    const iconPositionClass = hasText.value ? (props.iconPosition.value === 'prefix' ? 'mr-2' : 'ml-2 order-1') : ''
    return classNames('inline-flex items-center transition duration-100', iconPositionClass)
  })

  const loadingClasses = computed(() => {
    const variant = props.variant.value
    const loadingColorClass = buttonColorClasses.loading[variant]
    return classNames(loadingColorClass)
  })

  const iconClasses = computed(() => {
    const variant = props.variant.value
    const iconClass = props.iconClass.value
    const iconColorClass = iconClass.includes('text-') ? '' : buttonColorClasses.icon[variant]
    return classNames('transition duration-100', iconColorClass, iconClass)
  })

  return {
    wrapperClasses: bindClasses,
    textClasses,
    iconSpanClasses,
    loadingClasses,
    iconClasses,
  }
}
