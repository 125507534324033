// Generated by @nuxtjs/dayjs
import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'
dayjs.locale('pt-br')

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)
import isYesterday from 'dayjs/plugin/isYesterday'
dayjs.extend(isYesterday)
import isTomorrow from 'dayjs/plugin/isTomorrow'
dayjs.extend(isTomorrow)
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

