import { isArray, isEmpty, isEmptyString, isNullish, isPlainObject, isString, isUndefined } from './is'

export const fallback = <T = unknown, F = T>(value: T | undefined | null, fallback: F): T | F => {
  // Fallback is not defined, so return the value
  if (isUndefined(fallback)) {
    return value as T
  }
  // Nullish
  if (isNullish(value)) {
    return fallback
  }
  // Empty array or object
  if ((isArray(value) || isPlainObject(value)) && isEmpty(value)) {
    return fallback
  }
  // Empty string
  if (isString(value) && isEmptyString(value)) {
    return fallback
  }
  return value
}
