<script lang="ts" setup>
// Based on https://github.com/gitFoxCode/nuxt-icons/blob/main/src/runtime/components/nuxt-icon.vue
import type { PropType } from 'vue'
import type { LIconPropIcon } from './types'

const props = defineProps({
  icon: {
    type: String as PropType<LIconPropIcon>,
    required: true,
  },
  filled: {
    type: Boolean,
    default: true,
    required: false,
  },
  size: {
    type: Number,
    default: 24,
  },
})
// Icon
const rawIcon = ref<string | Record<string, any>>('')
let hasStroke = false

// Init
watchEffect(async () => {
  try {
    const iconsImport = import.meta.glob(['/assets/icons/**/**.svg', '@ui/assets/icons/**/**.svg'], {
      query: '?raw',
      import: 'default',
      eager: false,
    })
    const iconKey = Object.keys(iconsImport).find((key) => {
      const fileName = key.split('/').pop() // Get the file name from the key
      const iconName = fileName?.split('.svg')[0] // Remove the .svg extension
      return iconName === props.icon // Compare with props.icon
    })
    const _rawIcon = await iconsImport[iconKey]()
    if (_rawIcon.includes('stroke')) {
      hasStroke = true
    }
    rawIcon.value = _rawIcon
  } catch {
    console.error(`[nuxt-icons] Icon '${props.icon}' doesn't exist in 'assets/icons'`)
  }
})
</script>

<template>
  <span
    class="nuxt-icon"
    :class="{ 'nuxt-icon--fill': !filled, 'nuxt-icon--stroke': hasStroke && !filled }"
    :style="{ fontSize: size + 'px' }"
    v-html="rawIcon"
  />
</template>

<style>
.nuxt-icon svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

/* stylelint-disable */
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}

.nuxt-icon.nuxt-icon--stroke,
.nuxt-icon.nuxt-icon--stroke * {
  stroke: currentColor !important;
}
/* stylelint-enable */
</style>
