import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp, $router }) => {
  const config = useRuntimeConfig()
  const sentryOptions = config.public.sentry

  // Defined by the module, can't be changed by the user
  const moduleOptions = {
    app: vueApp,
    dsn: sentryOptions.dsn,
    integrations: [
      Sentry.browserTracingIntegration({
        ...sentryOptions.tracing.browserOptions,
        router: $router,
      }),
    ],
    tracesSampleRate: sentryOptions.tracing.tracesSampleRate,
    logErrors: sentryOptions.logErrors,
  }
  // Defined by the user
  const userOptions = sentryOptions.config

  const shouldInitSentry = !sentryOptions.disable || !moduleOptions.dsn

  if (process.env.NODE_ENV === 'development') {
    console.log('[development] sentry is enabled', shouldInitSentry)
  }

  Sentry.init({
    ...userOptions,
    ...moduleOptions,
    enabled: shouldInitSentry,
    hooks: [], // Add an empty array for the hooks property
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
