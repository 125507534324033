export enum LDropzoneStatus {
  EMPTY = 'EMPTY',
  ADDED = 'ADDED',
  QUEUED = 'QUEUED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  REMOVING = 'REMOVING',
  REMOVED = 'REMOVED',
  ERROR = 'ERROR',
}

export const LDropzoneImageAcceptType = ['image/png', 'image/jpeg']
