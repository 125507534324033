import type { FormKitNode } from '@formkit/core'

export default function disabled(node: FormKitNode): void {
  node.on('prop:options', ({ payload }) => {
    node.props.disabled = shouldDisable(node, { options: payload })
  })

  node.on('prop:emptyMessage', ({ payload }) => {
    node.props.disabled = shouldDisable(node, { emptyMessage: payload })
  })

  node.on('prop:searchable', ({ payload }) => {
    node.props.disabled = shouldDisable(node, { searchable: payload })
  })

  node.on('created', () => {
    node.props.disabled = shouldDisable(node)
  })
}

function shouldDisable(
  node: FormKitNode,
  { options = node.props.options, emptyMessage = node.props.emptyMessage, searchable = node.props.searchable } = {},
): true | undefined {
  // We have options, so we should not disable
  if (options.length !== 0) {
    return undefined
  }

  // We have a selected value, but options are not loaded yet
  if (node._value && node.context?.state.loading && !searchable) {
    return true
  }

  // If we have an empty message or a search input, we should not disable
  return !emptyMessage && !searchable ? true : undefined
}
