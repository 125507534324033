import type { DehydratedState, VueQueryPluginOptions } from '@tanstack/vue-query'
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query'
import { FetchError } from 'ofetch'

export default defineNuxtPlugin((nuxt) => {
  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000, // 1 minute
        refetchOnWindowFocus: false,
        retry: (failureCount: number, error: unknown) => {
          return error instanceof FetchError && error.statusCode && error.statusCode >= 500 ? failureCount < 1 : false
        },
        retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30000),
      },
    },
  })
  const pluginOptions: VueQueryPluginOptions = { queryClient }

  nuxt.vueApp.use(VueQueryPlugin, pluginOptions)
  const vueQueryClient = useState<DehydratedState | null>('vue-query')

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryClient.value = dehydrate(queryClient)
    })
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryClient.value)
    })
  }
})
